html[data-company="gsk"] {
  --color__text: #222120;

  --color__brand-dark: #3b2511;
  --color__brand: #784650;
  --color__brand-light: #cfadb4;
  --color__brand-lighter: #e7d6d9;
  --color__background: #e4d7d9;

  --color__back: #f5f5f5;

  h1 {
    font-family: "Granville", "Mulish", "Roboto", sans-serif;
  }
}

html[data-company="fnf"] {
  --color__text: #000;

  --color__brand-dark: #3b3c43;
  --color__brand: #Ca0233;
  --color__brand-light: #fae9e3;
  --color__brand-lighter: #fdf4f3;
  --color__background: #fff;

  --color__back: #f5f5f5;

  h1 {
    font-family: "Mulish", "Roboto", sans-serif;
  }
}

h1 {
  color: var(--color__brand-dark);
  font-weight: 500;
  text-transform: uppercase;
}

.headline {
  display: flex;
  margin-bottom: 30px;
  justify-content: space-between;
  align-items: top;
  flex-direction: column-reverse;
  padding: 1px;

  &__text {
    text-align: center;

    h1 {
      margin-bottom: 20px;
    }

    &__bold {
      display: block;
      margin-bottom: 10px;
    }
  }

  &__logo-wrap {
    text-align: center;
    display: inline-block;
    margin-bottom: 30px;
    img {
      height: 50px;
    }
  }
}

@media (min-width: 768px) {
  .headline {
    flex-direction: row;
    margin-bottom: 0;

    &__logo-wrap {
      margin-bottom: 0;

      img {
        height: 70px;
      }
    }

    &__text {
      text-align: left;
    }
  }

  html[data-company="fnf"] {
    .headline {
      &__logo-wrap {
        img {
          height: 90px;
        }
      }
    }
  }
}

.error {
  margin: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  color: red;
  font-weight: bold;
  transform: translate(-50%, -50%);
  padding: 10px;
}
